import React from 'react';
import {Drawer, message, Input, Table, Button, Switch, Tooltip, Avatar} from "antd";
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as Actions from "./../../store/widgets/actions";
import * as C from "./../../store/widgets/constants";
import {AppConsumer} from '../../context/app';
import {t} from "../../core/library/i18n";
import {parseUrlParams} from "../../core/library/url";
import WidgetForm from './../../components/forms/widget';
import Config from "../../config";
import './style.scss';


const mapStateToProps = state => ({
    answer: state.WidgetReducer,
});

const mapDispatchProps = dispatch => ({
    actions: bindActionCreators(Actions, dispatch)
});


const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};


class WidgetHeader extends React.Component {

    static defaultProps = {
        context: {},
    };

    static propTypes = {
        context: PropTypes.instanceOf(Object).isRequired,
    };

    componentDidMount() {
        this.props.context.actions.updateHeader({
            title: t('WIDGETS_LIST'),
        })
    }

    render() {
        return null;
    }
}

export class WidgetIndexController extends React.Component {

    static defaultProps = {
        answer: {},
        actions: {},
    };

    static propTypes = {
        answer: PropTypes.instanceOf(Object).isRequired,
        actions: PropTypes.instanceOf(Object).isRequired,
    };



    constructor(props) {
        super(props);
        let url = parseUrlParams();
        const wType = this.props.match && this.props.match.params && this.props.match.params.type ? this.props.match.params.type : '';
        this.state = {
            loading: false,
            list: [],
            total: 0,
            limit: 0,
            wType,
            page: url.page ? parseInt(url.page, 10) : 1,
            search: url.search ? decodeURIComponent(url.search) : "",
            drawer: false,
            item: {},
            language: url.lang ? url.lang : '',
        };
    }

    sortUpIndex = (index) => {
        let list = this.state.list;
        list = reorder(list, index, index - 1);
        this.setState({ list, loading: true }, () => {
            let data = {};
            this.state.list.map((i, index) => {
                data[i.id] = index;
                return i;
            });
            this.props.actions.UpdateSort(data);
        });
    };

    sortDownIndex = (index) => {
        let list = this.state.list;
        list = reorder(list, index, index + 1);
        this.setState({ list, loading: true }, () => {
            let data = {};
            this.state.list.map((i, index) => {
                data[i.id] = index;
                return i;
            });
            this.props.actions.UpdateSort(data);
        });
    };


    openDrawer = () => {
        this.setState({ drawer: true });
    };

    hideDrawer = () => {
        this.setState({ drawer: false, item: {} });
    };

    loadWidgets = () => {
        this.props.actions.GetWidgets(this.state.page, this.state.search, this.state.wType, this.state.language);
    };

    updatePageHandle = (page) => {
        this.setState({ page }, () => {
            this.props.history.push(`${this.props.location.pathname}?page=${this.state.page}&search=${this.state.search}`);
            this.props.actions.GetWidgets(this.state.page, this.state.search);
        });
    };

    updateWidgetStatus = (id, published) => {
        this.props.actions.UpdateWidget(id, { published });
    };

    onUpdate = (values) => {
        if (this.state.item && this.state.item.id) {
            if (typeof values.language === "undefined" || Array.isArray(values.language)) {
                values.language = null
            }
            this.props.actions.UpdateWidget(this.state.item.id, values);
        } else {
            this.onCreate(values);
        }
    };

    onCreate = (values) => {
        if (window.location.href.indexOf('widgets_mainpage') + 1 > 0) {
            values.main_page = true;
        }
        this.props.actions.AddWidget(values);
    };

    componentDidMount() {
        this.loadWidgets();
    }



    componentWillReceiveProps(p) {
        const wType = p.match && p.match.params && p.match.params.type ? p.match.params.type : '';
        if (this.state.wType !== wType) {
            this.setState({
                loading: false,
                list: [],
                total: 0,
                limit: 0,
                wType,
                page: 1,
                search: "",
                drawer: false,
                item: {},
            }, () => {
                this.loadWidgets()
            })
        }
        if (p.answer && p.answer.state) {
            switch(p.answer.state) {
                case C.WIDGETS_LOAD_START:
                case C.WIDGET_UPDATE_START:
                case C.WIDGET_ADD_START:
                case C.WIDGETS_SORT_UPDATE_START:
                    this.setState({ loading: true });
                    break;
                case C.WIDGETS_SORT_UPDATE_FINISH:
                    this.loadWidgets();
                    break;
                case C.WIDGETS_LOAD_FINISH:
                    let data = p.answer.data && p.answer.data.data ? p.answer.data.data : {};
                    let wList = data.list || [];

                    if (window.location.href.indexOf('widgets_ads') + 1 > 0) {
                        wList = wList.filter(i => {
                            if (i.data_type && i.data_type.length > 1) return false;
                            return i.data_type[0] === 'Banner';
                        });
                    } else {
                        wList = wList.filter(i => {
                            return (i.data_type && i.data_type.length === 1 && i.data_type[0] === 'Banner' && i.code_name !== 'banner_constructor') ? false : true;
                        });
                    }
                    this.setState({
                        loading: false,
                        limit: data.limit || 0,
                        list: wList,
                        total: data.total || 0,
                    });
                    break;
                case C.WIDGET_UPDATE_FINISH:
                    this.setState({ drawer: false, loading: false, item: {}}, () => {
                        message.success(t('WIDGET_UPDATED'));
                        this.loadWidgets();
                    });
                    break;
                case C.WIDGET_ADD_FINISH:
                    this.setState({ drawer: false, loading: false, item: {}}, () => {
                        message.success(t('WIDGET_CREATED'));
                        this.loadWidgets();
                    });
                    break;
                case C.WIDGETS_LOAD_ERROR:
                    this.setState({
                        loading: false,
                    }, () => {
                        message.error(t(`ERROR_LOAD_WIDGETS`));
                    });
                    break;
                case C.WIDGET_UPDATE_ERROR:
                    this.setState({ loading: false }, () => {
                        message.error(t(`ERROR_UPDATE_WIDGET`));
                    });
                    break;
                case C.WIDGET_ADD_ERROR:
                    this.setState({ loading: false }, () => {
                        message.error(t(`ERROR_ADD_WIDGET`));
                    });
                    break;
                case C.WIDGETS_SORT_UPDATE_ERROR:
                    this.setState({ loading: false }, () => {
                        message.error(t(`ERROR_UPDATE_WIDGET`));
                    });
                    break;
                default: break;
            }
        }
    }

    updateSearchHandle = (search) => {
        this.setState({ search: encodeURIComponent(search), page: 0 }, () => {
            this.props.history.push(`${this.props.location.pathname}?page=${this.state.page}&search=${this.state.search}&lang=${this.state.language}`);
            this.loadWidgets();
        });
    };

    render() {
        let Columns = [
            {
                title: t('TITLE'),
                key: 'title',
                render: row => {
                    let title = row.title || t('NOT_SET');
                    let langSlug = row.language && row.language.slug ? row.language.slug : '';
                    let sTitle = row.adminTitle;
                    if (row.titleEn !== "") {
                        sTitle = row.titleEn;
                    }
                    return <div className={`wlang__row`}><span>{title} / {sTitle}</span>{langSlug && <Avatar src={`/images/langs/${langSlug}.svg`} size={32} shape={`square`} />}</div>
                }
            },
           
            {
                title: t('PUBLISHED'),
                key: 'published',
                align: 'center',
                render: row => {
                    return <Switch defaultChecked={row.published} onChange={(published) => this.updateWidgetStatus(row.id, published)}/>;
                },
            },
            {
                title: t('DOC_TYPES'),
                key: 'doc_types',
                render: row => {
                    return row.data_type && Array.isArray(row.data_type) && row.data_type.length > 0 ? row.data_type.join(', ') : t('ALL');
                },
            },
            {
                title: t('DOCS'),
                key: 'doc_size',
                align: 'center',
                render: row => {
                    let d = row.data && Array.isArray(row.data) ? row.data.length : 0
                    return `${d} / ${row.max_size}`
                },
            },
            {
                title: t('WIDGET_VIEW'),
                key: 'view',
                align: 'center',
                render: row => {
                    let views = Config.WIDGETS.VIEWS.filter(i => i.key === row.view);
                    return views && views.length > 0 ? views[0].name : `[not set]`
                },
            },
        ];
        if (this.state.wType !== '') {
            Columns.push({
                title: t('SORT'),
                key: 'sort',
                align: 'center',
                render: (row, v, index) => {
                    return (
                        <Button.Group>
                            <Button icon={"arrow-up"} onClick={() => this.sortUpIndex(index)} disabled={index === 0}/>
                            <Button icon={"arrow-down"} onClick={() => this.sortDownIndex(index)} disabled={index === this.state.list.length - 1}/>
                        </Button.Group>
                    );
                },
            })
        } else {
            Columns.push({
                title: `Расположение`,
                key: 'position',
                align: 'center',
                render: (row) => {
                   if (row.type === '') {
                       return `[not set]`;
                   } else {
                       return row.type || '[not set]';
                   }
                },
            })
        }

        Columns = [...Columns, ...[
            {
                title: t('ACTIONS'),
                key: 'actions',
                align: 'center',
                render: row => {
                    return (
                        <Button.Group>
                            <Tooltip title={t('EDIT_WIDGET')}>
                                <Button type={`primary`} icon={`edit`} onClick={() => {
                                    this.setState({ drawer: true, item: row });
                                }}/>
                            </Tooltip>
                            <Tooltip title={t('VIEW_WIDGET')}>
                                <Button type={`primary`} icon={`eye`} onClick={() => this.props.history.push(`/widgets/${row.id}`)}/>
                            </Tooltip>
                        </Button.Group>
                    );
                }
            }
        ]];
        return (
                <AppConsumer>
                    {context => {
                        return (
                            <React.Fragment>
                                <WidgetHeader context={context} />
                                <Drawer title={t('WIDGET_ADD_TITLE')} onClose={this.hideDrawer} visible={this.state.drawer} width={320}>
                                    {this.state.drawer && <WidgetForm onSubmit={this.onUpdate} item={this.state.item} wType={this.state.wType}/>}
                                </Drawer>
                                <div className={`app_view_table widget_view`}>
                                    <Table
                                        title={() => {
                                            return (
                                                <React.Fragment>
                                                    <div className={`widget--search`}>
                                                        <Button type={`primary`} onClick={this.openDrawer}>{t('CREATE')}</Button>
                                                        <Input.Search
                                                            defaultValue={this.state.search}
                                                            enterButton={t('SEARCH')}
                                                            onSearch={this.updateSearchHandle}
                                                        />
                                                    </div>
                                                </React.Fragment>
                                            )
                                        }}
                                        bordered
                                        columns={Columns}
                                        dataSource={this.state.list}
                                        rowKey={(item) => item.id}
                                        loading={this.state.loading}
                                        pagination={{
                                            pageSize: this.state.limit,
                                            defaultCurrent: this.state.page,
                                            total: this.state.total,
                                            onChange: this.updatePageHandle,
                                            current: this.state.page,
                                            hideOnSinglePage: true,
                                        }}
                                    />
                                </div>
                            </React.Fragment>
                        );
                    }}
                </AppConsumer>
        );
    }
}

export const WidgetIndex = connect(
    mapStateToProps,
    mapDispatchProps
)(WidgetIndexController);

export default WidgetIndex;



import React from 'react';
import PropTypes from 'prop-types';
import {Button, Tag, Tooltip, Popconfirm, Icon, Checkbox} from 'antd';

import {t} from "../../../core/library/i18n";
import Config, { GetImagePath } from "../../../config";
import Moment from 'moment';
import 'moment/locale/ru.js';

const TypeColours = {
    'Cover': '#f50',
    'Image': '#108ee9',
    'Video': '#b20932'
};


export class MediaItem extends React.Component {

    static defaultProps = {
        files: {},
        title: '',
        CAN_HIDE: false,
        type: ''
    };

    static propTypes = {
        files: PropTypes.instanceOf(Object).isRequired,
        title: PropTypes.string.isRequired,
        CAN_HIDE: PropTypes.bool.isRequired,
        type: PropTypes.string.isRequired,
    };

    getMedia = () => {
        let dimensions = ['image-m', 'image-l', 'cover', 'preview', 'medium', 'original'];
        let path = '';
        let files = this.props.files;

        for (let index in dimensions) {
            let i = dimensions[index];
            if (files[i] && files[i].path) {
                path = files[i].path;
                break;
            }
        }

        let path_prefix = ``;
        let original_prefix = ``;
        switch (this.props.type) {
            case "Image":
            case "Cover":
                path_prefix = Config.MEDIA.CDN.IMAGES + "/";
                original_prefix = Config.MEDIA.CDN.IMAGES + "/";
                break;
            case "Video":
                path_prefix = original_prefix = Config.MEDIA.CDN.VIDEO + "/";
                break;
            case "PDF":
                path_prefix = Config.MEDIA.CDN.PDF + "/";
                original_prefix = Config.MEDIA.CDN.PDF + "/";
                break;
            default:
                break;
        }

        if (["Image", "Cover"].indexOf(this.props.type) + 1 > 0 && path_prefix.indexOf('cdnimages.') + 1 > 0) {
            let path_params = path.split('/');
            path_params[path_params.length - 1] = `300_${path_params[path_params.length - 1]}`;
            path = path_params.join('/');
        }

        return {
            "preview": path ? `${path_prefix}${path}` : '',
            "original": files['original'] && files['original'].path ? `${original_prefix}${files['original'].path}` : '',
        };
    };

    getImageTitle = () => {
        let print_data = [];
        if (this.props['title']) {
            print_data.push(this.props['title']);
        }
        if (this.props['description']) {
            print_data.push(this.props['description']);
        }
        if (this.props['properties'] && this.props['properties']['copyright']) {
            print_data.push(this.props['properties']['copyright']);
        }
        return print_data.join(' / ');
    }

    /*@TODO: move to config*/
    generateSiteImage = () => {
        let files = this.props.files;
        let path = files['original']  ? files['original'].path : '';
        if (!path) return '/images/nocover.png';
        return GetImagePath(files, '1280');
    }

    generateSiteVideo = () => {
        let files = this.props.files;
        if (files['preview'] && files['preview'].path) {
            return `https://video.vm.ru/mp4/${files['preview'].path}`;
        }
        return false;
    }

    getRatio = () => {
        if (!this.props.files || !this.props.files["original"] || !this.props.files["original"]["ratio"]) {
            return null;
        }
        if (this.props.files["original"]["ratio"] === "1.78" || this.props.files["original"]["ratio"] === "1.77") {
            return <div className='image--ratio'><Tag color='orange'>16/9</Tag></div>
        }
        
    }

    render() {
        const view = this.props.view;
        let media = this.getMedia();
        let blockBackground = media.preview ? {backgroundImage: `url(${media.preview})`} : ``;
        let imageTitle = this.props['title'] || "";
        return (
            <div className={`media__wrapper`}>
                <div className={`media__item item__${view}`}>
                    <div className="image">
                        <div className={`bookmark_action`}><Tooltip title={`Избранное`}><Checkbox checked={this.props.is_bookmark} onChange={(e) => {
                            this.props.toggleMediaBookmark(this.props.id, e.target.checked);
                        }}/></Tooltip></div>
                         <Tag size={`small`} color={TypeColours[this.props.type] || ""} className={`ftag_main`}>
                                {t(`MEDIAGALLERY_TYPE_${this.props.type}`)}{this.props.media_id ? ` / ${this.props.media_id}` : ``}
                                <Checkbox
                                    className={`remove_checkbox`}
                                    checked={this.props.deleteList.indexOf(this.props.id) + 1 > 0}
                                    onChange={(e) => {
                                        if (e.target.checked) {
                                            this.props.addToDelete(this.props.id);
                                        } else {
                                            this.props.removeFromDelete(this.props.id);
                                        }
                                    }}
                                />
                            </Tag>
                        <a className={`preview fimage type_${this.props.type.toLowerCase()} ${this.props.CAN_HIDE ? `can_bookmark` : ``}`} style={blockBackground}
                           data-fancybox href={media.original}>
                           
                            <div className={`created_at`}>
                                <span>{Moment(this.props.createdAt).format(Config.DATE_FORMAT)}</span>
                            </div>
                            {this.props.isUsing && <div className={`image--usage`}><Tooltip title={`Картинка использовалась за последние 48 часов`}><Icon type="clock-circle"/></Tooltip></div>}
                            {this.props.expired && <div className={`image--expired`}><Icon type="info" /></div>}
                            {this.getRatio()}
                        </a>
                        <div className="item__actions">
                            {['Video', 'Image', 'Cover'].indexOf(this.props.type) + 1 > 0 && (
                                <React.Fragment>
                                    <Button type={`primary`} icon={"unordered-list"} onClick={() => {
                                        this.props.showMaterialModal(this.props.id)
                                    }}/>
                                    <Button type={`primary`} icon={`edit`} onClick={() => {
                                        this.props.onUpdate(this.props.id, this.props);
                                    }}/>
                                </React.Fragment>
                            )}
                            <Tooltip title={`Скачать оригинал`}>
                                <Button
                                    type={`primary`}
                                    icon={`cloud-download`}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        window.open(media.original);
                                    }}
                                />
                            </Tooltip>
                            {(['Video'].indexOf(this.props.type) + 1 > 0 && this.generateSiteVideo(media.original)) && (
                                <Tooltip title={`Скачать для сайта`}>
                                    <Button
                                        type={`primary`}
                                        icon={`cloud-download`}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            window.open(this.generateSiteVideo(media.original));
                                        }}
                                    />
                                </Tooltip>
                            )}
                            {['Image', 'Cover'].indexOf(this.props.type) + 1 > 0 && (
                                <Tooltip title={`Скачать для сайта`}>
                                    <Button
                                        type={`primary`}
                                        icon={`cloud-download`}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            window.open(this.generateSiteImage(media.original));
                                        }}
                                    />
                                </Tooltip>
                            )}
                          
                                <Popconfirm
                                    title={t("MEDIAGALLERY_DELETE_CONFIRM")}
                                    okText={t("MEDIAGALLERY_DELETE_OK")}
                                    cancelText={t("MEDIAGALLERY_DELETE_REJECT")}
                                    onConfirm={() => {
                                        this.props.onDelete(this.props.id);
                                    }}
                                >
                                    <Button type={`danger`} icon={`delete`}/>
                                </Popconfirm>
                        

                        </div>
                    </div>
                    <div className="item__info">
                        {this.props.view === 'card' && (
                            <div className="title">
                                <Tooltip title={imageTitle}>{imageTitle.length > 40 ? imageTitle.substr(0, 37) + "..." : imageTitle}</Tooltip>
                                {(this.props.properties && this.props.properties.copyright) && <div className={`subtitle`}>{this.props.properties.copyright}</div>}
                            </div>
                        )}
                        {this.props.view === 'list' && (
                            <React.Fragment>
                                {this.props.title !== "" && <div className="title">{this.props.title}</div>}
                                {this.props.description !== "" &&
                                <div className="description">{this.props.description}</div>}
                                <div className="image_info">
                                    {this.props.properties && this.props.properties.copyright ? this.props.properties.copyright + '/ ' : ''}
                                    {Moment(this.props.createdAt).format(Config.DATE_FORMAT)}
                                </div>
                                {this.props.keywords && <div className="keywords">{
                                    this.props.keywords.split(",").map((i, index) => {
                                        i = i.trim();
                                        if (i !== "") {
                                            return <Tag key={`keyword_${this.props.id}_${index}`}>{i}</Tag>
                                        }
                                        return null;
                                    })
                                }</div>}
                                {this.props.user_email &&
                                <div className={`user_email`}>Загрузил: {this.props.user_email}</div>}
                            </React.Fragment>
                        )}

                    </div>
                </div>
            </div>
        );
    }
}

import MediaSources from "../../media_sources";
import { parseUrlParams } from '../../../core/library/url';

const isLocal = window.location.hostname.indexOf('localhost') + 1 > 0;

export const teleport = {
    SHOW_LANGS: true,
    BASE_DOMAIN: 'https://stage.abzac.media',
    APP_CLASS: 'vm',
    PROJECT_NAME: 'vm',
    PROJECT_PREFIX: 'vm',
    LOCALE: "ru",
    PREVIEW_DOMAIN: "",
    PREVIEW_HASH: "AE30845FBF6EE09305710B753A9C264C",
    SOCKET: 'http://localhost:9001',


    COMMENTS: {
        HOST: 'http://localhost:9002/comments_api/api/v1',
        SERVICES: ['youtube', 'telegram', 'vk'],
    },

    PROJECT: {
        // project name
        NAME: 'teleport',
        // project default layout
        LAYOUT: "default",
        // project api path
        API: isLocal ? `http://localhost:8086/api/backend` : `/api/backend`,
        ELASTIC_API: isLocal ? `http://localhost:8086/api/backend/elastic` : `/api/backend/elastic`,
    },


    OAUTH: {
        // url for oauth domain
        URL: `#`,
        // current project domain
        PROJECT_DOMAIN: `localhost:3000`,
        // current project url path
        PROJECT_PATH: `http://localhost:3000`,
        // path for oauth api
        API: `#`,
        // path for oauth users
        OAUTH_USER_PATH: `#`,
        // prefix for authorization tokens
        PREFIX: 'oauth_',
    },

    ELASTIC: {
        API: isLocal ? "http://localhost:8086/api/backend/elastic" : "/api/backend/elastic"
    },


    LANGS: [
        {title: "English", domain: "http://localhost:3000"},
        {title: "Russian", domain: "http://ru.localhost:3000"}
    ],

    UPLOAD: {
        "path": isLocal ? "http://localhost:8086/api/backend/media" : "/api/backend/media"
    },

    // vmru config
    VIEWS: {
        documents: {
            "article": "default_view_vm",
            "author": "authors_view",
            "category": "category_view",
            "tag": "tag_view",
            "seasons": "tag_view",
            "contacts_tags": "tag_view",
            "trend": "trend_view",
            "page": "page_view",
            "show": "show_view",
            "newspaper": "newspaper_view",
            "rnewspaper": "rnewspaper_view",
            "schedule": "schedule_view",
            "arates": 'arates_view',
            "aviews": 'aviews_view',
            "mcat": 'anons_view',
            "polls": 'poll',
            "covid": "covid",
            "stop": "stopwords",
            "contacts": "contacts_index",
            "contacts_type": "contacts_types",
            "redirects": "RedirectsViews",
            "channels": "ChannelsView",
        },
        documents_add: {
            "article": "add_article_vm",
            "trend": "add_trend",
            "news": "add_news",
            "author": "add_author",
            "category": "add_category_rbth",
            "tag": "add_tag",
            "seasons": "add_tag",
            "mcat": "anons_add",
            "banner": "add_banner",
            "page": "add_page",
            "newspaper": "add_newspaper",
            "rnewspaper": "add_rnewspaper",
            "episode": "add_episode",
            "battle": "add_battle",
            "schedule": "add_schedule",
            "arates": "arate_add",
            "aviews": "aviews_add",
            "infographic": "infographics_add",
            "polls": "poll_add",
            "covid": "covid",
            "afisha": "afisha_vm",
            "regionmat": "region_material",
            "mgallery": "mgallery",
            "stories": "stories",
            "receipt": "receipt",
            "stt": "stt",
            "stop": "add_stopwords",
            "contacts": "add_contacts",
            "redirects": "RedirectsAdd",
            "podcasts": "AddPodcast",
            "photos": "AddPhoto",
            "channels": "AddChannel",
        }
    },
    PHOTO_MENU: [{
        'path': '/',
        'component': 'media_gallery',
        'title': 'MEDIA_GALLERY_LIST',
        'icon': 'picture',
    },
        {
            'title': 'REGION_MATERIALS',
            'path': '/region_materials',
            'component': 'regions_controller',
            'icon': 'dashboard',
            'subroutes': [
                {
                    'path': '/documents/:type/:id',
                    'component': 'add_documents',
                },
                {
                    'path': '/documents/:type/:id/preview',
                    'component': 'preview',
                },
                {
                    'path': '/documents/:type/create',
                    'component': 'add_documents',
                },
                {
                    'path': '/documents/episode/:id/chat',
                    'component': 'episode_chat',
                },
                {
                    'path': '/archive',
                    'component': 'archive',
                },
                {
                    path: '/mediagallery',
                    component: 'media_gallery',
                }
            ],
        },

    ],
    MENU: [
        {
            'title': 'DASHBOARD',
            'path': '/',
            'component': 'dashboard_index',
            'icon': 'dashboard',

        },

        {
            'title': 'DOCUMENTS',
            'path': '/documents/:type',
            'component': 'default_document',
            'subroutes': [
                {
                    'path': '/documents/:type/:id',
                    'component': 'add_documents',
                },
                {
                    'path': '/documents/:type/:id/preview',
                    'component': 'preview',
                },
                {
                    'path': '/documents/:type/create',
                    'component': 'add_documents',
                },
                {
                    'path': '/documents/episode/:id/chat',
                    'component': 'episode_chat',
                },
                {
                    'path': '/archive',
                    'component': 'archive',
                },
            ],
            'icon': 'container',
            'submenu': [
                {
                    'title': 'CHANNELS',
                    'path': '/documents/channels',
                    'icon': 'bars',
                },
                {
                    'title': 'SCHEDULE',
                    'path': '/documents/schedule',
                    'icon': 'bars',
                },
            ]
        },
       
        {
            title: 'MEDIA_GALLERY_LIST',
            path: '/mediagallery',
            icon: 'picture',
            component: 'media_gallery',
        },
        {
            title: "INSTRUMENTS",
            icon: "code",
            'submenu': [
                {
                    title: "COLLAGE_MAKER",
                    path: "/collage_maker",
                    icon: "picture",
                    component: 'CollageMaker',
                },
                {
                    'title': 'REDIRECTS',
                    'path': '/documents/redirects',
                    'icon': 'experiment',
                },
                
            ]
        },
        {
            'title': 'BANNERS',
            'path': '/documents/banner',
            'icon': 'layout',
        },
        {
            'title': 'WIDGETS',
            'path': '/widgets',
            'icon': 'appstore',
            'component': 'widget',
            'submenu': [
                {
                    'title': 'WTYPE_ALL',
                    'path': '/widgets',
                    'icon': 'appstore',
                    'component': 'widget',
                },
                {
                    'title': 'WTYPE_MAIN_PAGE',
                    'path': '/widgets/type/main',
                    'icon': 'appstore',
                }
            ],
            'subroutes': [
                {
                    'path': '/widgets/:id',
                    'component': 'widget_edit',
                },
                {
                    'path': '/widgets/type/:type',
                    'component': 'widget',
                },
            ]
        },
        {
            'title': 'PAGES',
            'path': '/documents/page',
            'icon': 'file-text',
        },
        
        {
            'title': 'USERS',
            'path': '/users',
            'icon': 'user',
            'subroutes': [
                {
                    'path': '/users/:id',
                    'component': 'users_edit',
                },
                {
                    'path': '/users/groups/add',
                    'component': 'add_group',
                },
                {
                    'path': '/users/groups/:id',
                    'component': 'add_group',
                }
            ],
            'submenu': [
                {
                    'title': 'USERS',
                    'path': '/users',
                    'icon': 'user',
                    'component': 'users_index',
                },
                {
                    'title': 'GROUPS',
                    'icon': 'idcard',
                    'path': '/users/groups',
                    'component': 'groups_index',
                },
                {
                    'title': 'PERMISSIONS',
                    'icon': 'idcard',
                    'path': '/users/permissions',
                    'component': 'permission_index',
                }
            ]
        }
    ],

    BANNER_TEXT_COLORS: [
        { 'value': 'black',  'label': 'Без цвета'},
        { 'value': 'white',  'label': 'Белый'},
        { 'value': 'pink',  'label': 'Розовый'},
        { 'value': 'red',  'label': 'Красный'},
    ],
    BANNER_BG_COLORS: [
        { 'value': 'bgc--white',  'label': 'Белый'},
        { 'value': 'bgg--white-ttb',  'label': 'Белый градиент сверху-вниз'},
        { 'value': 'bgg--white-ltr',  'label': 'Белый градиент слева-направо'},
        { 'value': 'bgc--pink',  'label': 'Розовый'},
        { 'value': 'bgg--pink-ttb',  'label': 'Розовый градиент сверху-вниз'},
        { 'value': 'bgg--pink-ltr',  'label': 'Розовый градиент слева-направо'},
        { 'value': 'bgc--red',  'label': 'Красный'},
        { 'value': 'bgg--red-ttb',  'label': 'Красный градиент сверху-вниз'},
        { 'value': 'bgg--red-ltr',  'label': 'Красный градиент слева-направо'},
        { 'value': 'bgc--black',  'label': 'Черный'},
        { 'value': 'bgg--black-ttb',  'label': 'Черный градиент сверху-вниз'},
        { 'value': 'bgg--black-ltr',  'label': 'Черный градиент слева-направо'},
    ],
    WORKFLOW: {
        SHOW_LINK: true,
        PREPARE_LINK: (item) => {
            switch (item.type.toLowerCase()) {
                case "news":
                    return `https://dvapiva.ru/news/${item.publicId}-${item.slug}`;
                case "meaning":
                    return `https://dvapiva.ru/opinion/${item.publicId}-${item.slug}`;
                case "episode":
                    return `https://dvapiva.ru/tv/${item.publicId}-${item.slug}`;
                case "gallery":
                    return `https://dvapiva.ru/photo/${item.publicId}-${item.slug}`;
                case "infographic":
                    return `https://dvapiva.ru/infographic/${item.publicId}-${item.slug}`;
                case "battle":
                    return `https://dvapiva.ru/battle/${item.publicId}-${item.slug}`;
                case "show":
                    return ``;
                case "newspaper":
                    return ``;
                case "rnewspaper":
                    return ``;
                case "schedule":
                    return ``;
                case "author":
                    return `https://dvapiva.ru/author/${item.publicId}-${item.slug}`;
                case "trend":
                    return `https://dvapiva.ru/trend/${item.publicId}`;
                case "category":
                    return `https://dvapiva.ru/${item.slug}`;
                case "tag":
                    return `https://dvapiva.ru/tag/${item.slug}`;
                case "page":
                    return `https://dvapiva.ru/${item.slug}`;
                default:
                    return `https://dvapiva.ru/${item.category ? item.category.slug : ""}/${item.publicId}-${item.slug}`;
            }
        },
        SHOW_SEARCH: true,
        STATUSES: ['DRAFT', 'READY', 'ARCHIVE', 'PUBLISH'],
        STATUSES_COLOR: {
            'DRAFT': 'gray',
            'READY': 'volcano',
            'ARCHIVE': 'purple',
            'PUBLISH': 'green',
        },
        DEFAULT: 'DRAFT',
        LOCK_FOR: [
            'infographic',
            'episode',
            'article',
            'news',
            'gallery',
            'meaning'
        ],
        WORKFLOW_FOR: [
            'episode',
            'article',
            'news',
            'gallery',
            'meaning'
        ],
    },
    WIDGETS: {
        TYPES: ['Channels'],
        WTYPE: ['main'],
        VIEWS: [
            {name: 'Главный материал', key: 'main_material'},
            {name: 'Скролл новостной', key: 'news_scroll'},
            {name: 'Скролл подкасты', key: 'podcasts_scroll'},
        ],
    },

    MEDIA: {
        // path for cdn content
        CDN: {
            IMAGES: "/arch/images",
            VIDEO: "/arch/video",
            PDF: "/arch/pdf",
            AUDIO: "/arch/audio",
        },
        // available copyright sources
        SOURCES: MediaSources,
        // available upload types
        UPLOAD_AVAILABLE: ['jpg', 'gif', 'png', 'jpeg', 'mp4', 'mp3'],
        // available embedded sources
        EMBEDDED_SOURCES: ['youtube.com', 'facebook.com', 'youtu.be', `tvzvezda.ru`],
        EMEDDED_PREPARE: {
            'youtu.be': (path) => {
                let params = path.split('/');
                return (
                    `<iframe width="560" height="315" src="https://www.youtube.com/embed/${params[params.length - 1]}" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`
                );
            },
            'youtube.com': (path) => {
                let p = parseUrlParams(path);
                if (!p.v) return ``;
                return (
                    `<iframe width="560" height="315" src="https://www.youtube.com/embed/${p.v}" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`
                );
            },
            'facebook.com': (url) => {
                return (
                    `<iframe src="https://www.facebook.com/plugins/video.php?href=${encodeURIComponent(url)}&width=500&show_text=false&appId=526970354336335&height=280" width="500" height="280" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowTransparency="true" allow="encrypted-media" allowFullScreen="true"></iframe>`
                )
            },
            'tvzvezda.ru': (url) => {
                return (
                    `<iframe src="${url}" width="500" height="315" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowTransparency="true" allow="encrypted-media" allowFullScreen="true"></iframe>`
                )
            }
        },
        PREPARE: (path) => {
            if (path.indexOf('mp4/') + 1 > 0) return `https://video.vm.ru${path.replace('https://files.vm.ru', '')}`;
            return path;
        },
    },

};

export default teleport;

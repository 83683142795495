import React from 'react';
import FormGenerator from './../../../core/components/generators/form';
import {t} from './../../../core/library/i18n';
import PrepareInitialValue from "../../../core/library/form/prepare";

export class AddDefaultArticleVm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {reload: false};
        this.Fields = [
            {
                'component': 'input',
                'label': t('TITLE'),
                'name': "title",
                "show_count": true,
                'props': {
                    'type': 'text',
                    'placeholder': t('TITLE_PLACEHOLDER'),
                    'onBlur': (e) => {
                        if (e.target.value !== this.props.item.title) {
                            // this.props.onUpdate({title: e.target.value}, true);
                        }
                    }
                },
                'rules': [
                    {required: true, message: t('TITLE_ERROR_FILL')},
                ]
            },
            {
                'component': 'input',
                'label': t('SUBTITLE'),
                'name': "subtitle",
                "show_count": true,
                'props': {
                    'type': 'text',
                    'placeholder': t('TITLE_PLACEHOLDER'),
                },
            },
            {
                'component': 'textarea',
                'label': t('SUMMARY'),
                'name': 'summary',
                "show_count": true,
            },
           
            {
                'component': 'media',
                'type': 'Cover',
                'searchType': 'Cover,Image',
                'label': t('COVER_LABEL'),
                'name': 'media',
            },
            {
                'component': 'editor',
                'label': t('TEXT'),
                'name': 'text',
                'props': {},
            },
            {
                'component': 'slug',
                'label': t('SLUG'),
                'name': 'slug',
                'source_field': 'title',
                'rules': [
                    {required: true, message: t("SLUG_ERROR")},
                ]
            },
            {
                'component': 'datepicker',
                'label': t('PUBLISHED_AT'),
                'name': 'publishedAt',
                'props': {
                    placeholder: 'Укажите дату и время публикации',
                    showTime: true,
                }
            },
            {
                'component': 'group',
		        'label': t('DATABASE_LIST'),
                'fields': [
                    {
                        'component': 'autocomplete',
                        'type': 'Author',
                        'limit': 30,
                        'label': t('AUTHORS'),
                        'name': 'authors',
                        'props': {
                            placeholder: t('AUTHORS_PLACEHOLDER'),
                            mode: 'multiple',
                            allowClear: true,
                            showSearch: true,
                        },
                        'rules': [
                            {required: true, message: t('SELECT_AUTHORS_ERROR')},
                        ]
                    },
                    {
                        'component': 'autocomplete',
                        'type': 'Category',
                        'limit': 15,
                        'label': t('CATEGORY'),
                        'name': 'category',
                        'props': {
                            placeholder: t('SELECT_CATEGORIES'),
                            allowClear: true,
                            showSearch: true,
                
                        },
                        'rules': [
                            {required: true, message: t('SELECT_CATEGORIES')},
                        ]
                    },
                    {
                        'component': 'autocomplete',
                        'type': 'Tag',
                        'limit': 15,
                        'label': t('TAGS'),
                        'name': 'tags',
                        'props': {
                            placeholder: t('SELECT_TAGS'),
                            allowClear: true,
                            showSearch: true,
                            mode: 'multiple',
                        },
                    },
                    {
                        'component': 'autocomplete',
                        'type': 'Trend',
                        'limit': 15,
                        'label': t('TRENDS'),
                        'name': 'trends',
                        'props': {
                            placeholder: t('SELECT_TRENDS'),
                            allowClear: true,
                            showSearch: true,
                            mode: 'multiple',
                        },
                    },
                    {
                        'component': 'checkbox',
                        'label': t('IMPORTANT_NEWS'),
                        'name': 'options.important_news',
                    },
                    {
                        'component': 'checkbox',
                        'label': t('BREAKING_NEWS'),
                        'name': 'options.breaking_news',
                    },
                    {
                        'component': 'checkbox',
                        'label': t('EXCLUSIVE_NEWS'),
                        'name': 'options.exclusive',
                    },
                ]
            }
        ];

        if (['article', 'news', 'meaning'].indexOf(this.props.type) + 1 > 0) {
            this.Fields = [...this.Fields, ...[{
                'component': 'group',
                'label': t('CROSS_LINKS'),
                'fields': [
                    {
                        'name': 'crosslinks',
                        'component': 'crosslinks',
                        'label': t('CROSS_LINKS'),
                        'props': {
                            placeholder: t('SELECT_SUB_CATEGORIES'),
                            allowClear: true,
                            showSearch: true,
                            mode: 'multiple',
                        }
                    },
                ]
            }]];
        }
        
        this.Fields = [...this.Fields, ...[{
                'component': 'group',
                'label': t('PUBLISH_IN'),
                'fields': [
                    {
                        'component': 'checkbox',
                        'label': t('PUBLISH_AT_MAIN'),
                        'name': 'options.main',
                    },
                    {
                        'component': 'checkbox',
                        'label': t('PUBLISH_AT_NEWSLINE'),
                        'name': 'options.newsline',
                    },
        
                ]
            },
            {
                'component': 'group',
                'label': t(`DZEN_GROUP`),
                'fields': [
                    {
                        'component': 'checkbox',
                        'label': t('PUBLISH_AT_DZEN'),
                        'name': 'options.dzen',
                    },
                    {
                        'component': 'checkbox',
                        'label': t('DZEN_GREEN'),
                        'name': 'options.dzen_green',
                    },
                ],
            },
            {
                'component': 'group',
                'label': t('EXPORT_TO'),
                'fields': [
                    {
                        'component': 'checkbox',
                        'label': t('PUBLISH_AT_RSS'),
                        'name': 'options.rss',
                        'defaultChecked': false,
                    },
                    {
                        'component': 'checkbox',
                        'label': t('PUBLISH_AT_GOOGLE'),
                        'name': 'options.google_rss',
                        'defaultChecked': true,
                    },
                    {
                        'component': 'checkbox',
                        'label': t('PUBLISH_AT_SMI2'),
                        'name': 'options.smi2',
                        'defaultChecked': true,
                    },
                    {
                        'component': 'checkbox',
                        'label': t('PUBLISH_AT_PULSE'),
                        'name': 'options.pulse',
                    },
                    {
                        'component': 'checkbox',
                        'label': t('PUBLISH_AT_MIRROR'),
                        'name': 'options.mirror',
                    },
                    {
                        'component': 'checkbox',
                        'label': t('PUBLISH_AT_WIFI'),
                        'name': 'options.wifi_export',
                        'defaultChecked': true,
                    },
                    {
                        'component': 'checkbox',
                        'label': t('PUBLISH_AT_SBER'),
                        'name': 'options.sber_rss',
                        'defaultChecked': false,
                    },
                ]
            },
            {
                'component': 'group',
                'label': t('SEO_AND_VIEW'),
                'fields': [
                    {
                        'component': 'checkbox',
                        'label': t('HIDE_COVER'),
                        'name': 'options.hide_cover',
                    },
                    {
                        'component': 'checkbox',
                        'label': t('HIDE_GOOGLE_ADS'),
                        'name': 'options.removeGoogleAds',
                    },
                    {
                        'component': 'checkbox',
                        'label': t('NO_INDEX'),
                        'name': 'options.noindex',
                    },
                    {
                        'component': 'view',
                        'label': t('VIEW_FIELD'),
                        'name': 'view',
                        'type': 'doc',
                    },
                    {
                        'component': 'input',
                        'label': t('SEO_TITLE'),
                        'name': "seo.metaTitle",
                        'props': {
                            'type': 'text',
                            'placeholder': 'seo title',
                        },
                    },
                    {
                        'component': 'textarea',
                        'label': t('SEO_DESCRIPTION'),
                        'name': "seo.metaDescription",
                        'props': {
                            'type': 'text',
                            'placeholder': 'seo description',
                        },
                    },
                    {
                        'component': 'input',
                        'label': t('REDIRECT_TO'),
                        'name': "redirectTo",
                        'props': {
                            'type': 'text',
                            'placeholder': 'redirect url',
                        },
                    }
                ],
            }
        ]];
    }

    componentWillReceiveProps(p) {
        if (p.item && p.item.id) {
            this.Fields = PrepareInitialValue(p.item, this.Fields, p.showLock);
        }
        if (p.showLock !== this.props.showLock) {
            this.Fields = PrepareInitialValue(p.item, this.Fields, p.showLock);
            this.setState({reload: !this.state.reload}, () => {});
        }
    }

    onSubmit = (values) => {
        let result_values = {};
        Object.keys(values).map(i => {
            if (i !== "text" && values[i] !== "") {
                result_values[i] = values[i];
            }
            if (i === "text") {
                result_values[i] = values[i];
            }
            if (i === "summary") {
                result_values[i] = values[i];
            }
            return i
        });

        values = result_values;

        let media = [];
        if (values.media) {
            media = Object.keys(values.media).map(i => values.media[i]);
        }
        values.media = media;
        values.redirectTo = values.redirectTo || "";
        if (this.props.item && this.props.item.id) {
            this.props.onUpdate(values);
        } else {
            this.props.onCreate(values);
        }
    };

    render() {
        return (
            <div className={`app_add--view add_${this.props.type}`}>
                <FormGenerator
                    showLock={this.props.showLock}
                    fields={this.Fields}
                    onSubmit={this.onSubmit}
                    onUpdate={this.props.onUpdate}
                    onPreview={this.props.onPreview}
                    isEdit={this.props.isEdit}
                    loading={this.props.loading}
                    item={this.props.item}
                    showPreview={true}
                    updateStash={this.props.updateStash}
                    updateMedia={this.props.updateMedia}
                    updateItem={this.props.updateItem}
                    updateMaterialType={this.props.updateMaterialType}
                    sendPush={this.props.sendPush}
                />
            </div>
        );
    }
}

export default AddDefaultArticleVm;

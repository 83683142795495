import { Button, Popconfirm, Tag, Tooltip } from 'antd';
import Moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import { t } from '../../library/i18n';
import { MediaProp } from "../props";
import Config from './../../../config';
import AuthorsCard from './../authors/card';
import Cover from './../cover';
import Tags from './../tags';


const CardStatus = (p) => {
    if (p.published) return <Tag color={"#87d068"}>{t('published')}</Tag>;
    if (p.prepublished) return <Tag color={"#2db7f5"}>Запланирован</Tag>;
    return <Tag color={"#F50"}>{t('unpublished')}</Tag>;
};

const CardStatusSTT = (p) => {

    const colors = {
        'pending': '#2db7f5',
        'process': '#f50',
        'finished': 'green',
        'error': 'red',
    }
    return p.stt_status ? <Tag color={colors[p.stt_status] || ""}>{t(`STT_${p.stt_status}`)}</Tag> : null;
};

export const DefaultCardVM = (p) => {
    return (
        <div className={`list--card card--default card--${p.type} card--vm`}>
            <div className="card--info">
                <div className="header">
                    {!p.isReadmore && (
                        <div className="header--info">
                            {p.category && p.type !== 'Stt' && (
                                <div className={`categories`}>
                                    {t('CATEGORY')}: <Link to={`?category=${p.category.title}`}>{p.category.title}</Link>
                                </div>
                            )}
                            {p.type === 'Stt' && <div className={`categories`} />}
                        </div>
                    )}
                    <div className="header--status">
                        {p.type !== 'Stt' && p.workflow && (<div className="worflow--status">
                            <Tag style={{cursor: 'pointer'}} color={(Config.WORKFLOW && Config.WORKFLOW.STATUSES_COLOR && Config.WORKFLOW.STATUSES_COLOR[p.workflow]) ? Config.WORKFLOW.STATUSES_COLOR[p.workflow] : ""}>{t(p.workflow)}</Tag>
                        </div>)}
                        {p.type !== 'Stt' && p.options && p.options.needRayter !== undefined && p.options.needRayter && <Tooltip title={t('NEED_RAYTER')}><Tag color={"#108ee9"}>{t('NEED_RAYTER_SHORT')}</Tag></Tooltip>}
                        {p.type !== 'Stt' && p.options && p.options.needEditor !== undefined && p.options.needEditor && <Tooltip title={t('NEED_EDITOR')}><Tag color={"#f50"}>{t('NEED_EDITOR_SHORT')}</Tag></Tooltip>}
                        {p.type !== 'Stt' && p.options && p.options.needCorector !== undefined && p.options.needCorector && <Tooltip title={t('NEED_CORRECTOR')}><Tag color={"gold"}>{t('NEED_CORRECTOR_SHORT')}</Tag></Tooltip>}
                        {p.type !== 'Stt' && p.options && p.options.needPhoto !== undefined && p.options.needPhoto && <Tag color={"orange"}>{t('N_NEED_PHOTO')}</Tag>}
                        {p.type !== 'Stt' && p.options && p.options.needVideo !== undefined && p.options.needVideo && <Tag color={"orange"}>{t('N_NEED_VIDEO')}</Tag>}
                        {p.type !== 'Stt' && (<div className="status"><CardStatus {...p}/></div>)}
                        {p.type === 'Stt' && (<div className="status"><CardStatusSTT {...p}/></div>)}
                        {p.type !== 'Stt' && (<div className="date">{Moment(p.publishedAt).format(Config.DATE_FORMAT)}</div>)}
                        {p.type === 'Stt' && (<div className="date">{Moment(p.updatedAt).format(Config.DATE_FORMAT)}</div>)}
                    </div>
                </div>
                <div className="content">
                    {p.type !== 'Stt' && (<div className="cover"><Cover {...p}/></div>)}
                    <div className="info">
                        {!p.isReadmore && (<div className={"title"}>{p.title || t('NOT_SET')}</div>)}
                        {p.isReadmore && (<div className={"title"}><a style={{color: "#111"}} href={`/documents/${p.type.toLowerCase()}/${p.id}`} target={"_blank"} rel={"nofollow"}>{p.title || t('NOT_SET')} [{p.type}]</a></div>)}
                        <div className="info--row" style={{flexDirection: 'column'}}>
                            {p.authors && p.authors.length > 0 && <div className={`authors`}>{t('AUTHOR')}: <AuthorsCard list={p.authors}/></div>}
                        </div>
                        <div className="header--users" style={{flexDirection: 'row', display: 'flex', marginTop: 4}}>
                            {(p.createdBy && p.createdBy.email) && <div className="owner" style={{marginRight: 10}}><b>Создан:&nbsp;</b>{p.createdBy.email}</div>}
                            {(p.updatedBy && p.updatedBy.email) && <div className="owner" style={{marginRight: 10}}><b>Обновлен:&nbsp;</b>{p.updatedBy.email}</div>}
                        </div>
                    </div>
                </div>
                <div className="footer--statuses">
                        
                </div>
                {((p.locks && p.locks.user_id && p.locks.user_id.id) || (p.tags && p.tags.length > 0)) && (
                    <>
                    {!p.isReadmore && (
                        <div className="footer">
                            {(p.locks && p.locks.user_id && p.locks.user_id.id) && <div className={`footer--row`}><div className={`user--lock`}>{t(`IN_MATERIAL`)}: <Link to={`/users/${p.locks.user_id.id}`}>{p.locks.user_id.email}</Link></div></div>}
                            {p.tags && p.tags.length > 0 && (
                                <div className={`footer--row`}><Tags list={p.tags}/></div>
                            )}
                            {p.media && <div className={`footer--row`}><MediaProp list={p.media}/></div>}
                        </div>
                    )}
                    </>
                )}
            </div>
            {!p.isReadmore && (
                <div className="card--actions">
                    <Tooltip title={t('EDIT')}><Button type={`primary`} icon={`edit`} onClick={() => p.onEdit(p.id)}/></Tooltip>
                    <Tooltip title={t('VIEW')}><Button type={`primary`} icon={`eye`} onClick={() => p.onView(p.id)}/></Tooltip>
                    {((!p.locks || !p.locks.user_id) && p.type !== "Stt") && (
                        <Tooltip title={t('DELETE')}>
                            <Popconfirm title={t('SURE_DELETE')} okText={t('DELETE')} cancelText={t('CLOSE')} onConfirm={() => p.onDelete(p.id)}>
                                <Button type={`danger`} icon={`delete`}/>
                            </Popconfirm>
                        </Tooltip>
                    )}
                </div>
            )}
            {p.isReadmore && (
                <div className="card--actions">
                    <Tooltip title={`Вставить в текст`}><Button type={`primary`} icon={`plus`} onClick={() => p.onInsert(p)}/></Tooltip>
                </div>
            )}
        </div>
    );
};

DefaultCardVM.defaultProps = {
    title: '',
    summary: '',
    media: [],
    crosslinks: [],
    tags: [],
    authors: [],
    category: {},
};


DefaultCardVM.propTypes = {
    title: PropTypes.string.isRequired,
    summary: PropTypes.string.isRequired,
    media: PropTypes.arrayOf(Object).isRequired,
    crosslinks: PropTypes.arrayOf(Object).isRequired,
    tags: PropTypes.arrayOf(Object).isRequired,
    authors: PropTypes.arrayOf(Object).isRequired,
    category: PropTypes.instanceOf(Object).isRequired,

};

export default DefaultCardVM;

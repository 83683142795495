import React from 'react';
import { Table } from 'antd';
import { getMediaMaterials } from './../../../store/media/api';
import Config from './../../../config';
import Moment from 'moment';

export class MaterialsModal extends React.Component {

    state = {
        loading: true,
        list: [],
    };

    columns = [
        {
            title: `Заголовок`,
            key: `title`,
            render: r => {
                return <a target={`_blank`} href={this.prepareLink(r)}>{r.title}</a>
            },
        },
        {
            title: `Опубликован / Создан`,
            key: `published_at`,
            render: r => {
                return r.publishedAt ? Moment(r.publishedAt).format(Config.DATE_FORMAT) : Moment(r.createdAt).format(Config.DATE_FORMAT)
            },
        }
    ]

    prepareLink = (doc) => {
        if (this.props.isPhoto)  {
            return `https://absatz.media/news/${doc.publicId}-${doc.slug}`;
        }
        return `/documents/${doc.type.toLowerCase()}/${doc.id}`;
    }

    componentDidMount = async () => {
        let data = await getMediaMaterials(this.props.id);
        this.setState({ loading: false, list: data.data ? data.data : [] })
    }

    render() {
        return (
            <Table
                loading={this.state.loading}
                pagination={false}
                columns={this.columns}
                bordered={true}
                size={`small`}
                dataSource={this.state.list}
                rowKey={(item) => item.id}
            />
        );
    }
}
import React from 'react';
import PropTypes from 'prop-types';
import { t } from './../../../core/library/i18n';
import { Table, Button, Switch } from 'antd';
import Moment from 'moment';
import AuthorsFilter from './../../../components/filters/authors';
import Config from './../../../config';
import AutocompleteField from "../../../core/components/autocomplete/documents";


export class ScheduleView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.Columns = [
            {
                title: t('TITLE'),
                dataIndex: 'title',
                sorter: true,
            },
            {
                title: t('DATE_START'),
                key: 'publishedAt',
                sorter: true,
                render: row => Moment(row.publishedAt).format(Config.DATE_FORMAT) ,
            },
            {
                title: t('DATE_END'),
                key: 'createdAt',
                sorter: true,
                render: row => row.options && row.options.date_end ? Moment(row.options.date_end).format(Config.DATE_FORMAT) : '',
            },

            {
                title: t('PUBLISHED'),
                key: 'published',
                sorter: true,
                render: row => {
                    return <Switch size="small" checked={row.published} onChange={(published) => {
                        this.props.onUpdate(row.id, { published, visible: published });
                    }} />;
                },
            },



            {
                title: t('ACTIONS'),
                key: 'actions',
                width: 120,
                render: row => {
                    return (
                        <Button.Group>
                            <Button type={`primary`} icon={`edit`} onClick={() => {
                                this.props.onEdit(row.id);
                            }}/>
                            <Button type={`danger`} icon={`delete`} onClick={() => {
                                this.props.onDelete(row.id);
                            }}/>
                        </Button.Group>
                    );
                }
            }
        ];
    }

    componentDidMount() {
        const OptionsContext = this.props.options || {};
        if (OptionsContext.actions && OptionsContext.actions.updateHeader) {
            OptionsContext.actions.updateHeader({
                title: t('TAG_LIST'),
            })
        }
    }
    updateFilter = (search) => {
        this.props.onFilter({ search });
    };

    render() {
        return (
            <div className={`app_view_table ${this.props.type}_view`}>
                <div className="app_view--wrapper">
                    <div className={`app_view--table`}>
                        <Table
                            bordered
                            title={() => (
                                <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%'}}>
                                    <div style={{width: '80%'}}>
                                        <AuthorsFilter onCreate={this.props.onCreate} onSearch={this.updateFilter}/>
                                    </div>
                                    <div style={{width: '20%', paddingLeft: 12}}>
                                        <AutocompleteField filter={false} type={'Channels'} limit={250} props={{style: {width: '100%'}}}/>
                                    </div>
                                </div>
                            )}
                            dataSource={this.props.items}
                            columns={this.Columns}
                            rowKey={(item) => item.id}
                            onChange = {(pagination, filters, sorter) => {
                                let sortOrder = '-createdAt';
                                if(sorter.columnKey) {
                                    sortOrder = `${sorter.order === 'ascend' ? '' : '-'}${sorter.columnKey}`;
                                }
                                this.props.updateSort(sortOrder);
                            }}
                            pagination={{
                                pageSize: this.props.limit,
                                defaultCurrent: this.props.page,
                                total: this.props.total,
                                onChange: this.props.updatePageHandle,
                                current: this.props.page,
                                hideOnSinglePage: true,
                                showSizeChanger: true,
                                pageSizeOptions: ["30", "60", "100"],
                                onShowSizeChange: (current, size) => this.props.updateLimit(size),
                            }}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

ScheduleView.defaultProps = {
    options: {
        actions: {
            updateHeader: () => {},
        }
    },
};

ScheduleView.propTypes = {
    options: PropTypes.instanceOf(Object).isRequired,
};


export default ScheduleView;

import React from 'react';
import PropTypes from 'prop-types';
import { Input, Empty, Button, Pagination, Spin, Tooltip, Modal, Tag } from 'antd';
import Moment from 'moment';
import { t } from './../../core/library/i18n';
import Config from './../../config';
import Cover from './../../core/components/cover';
import WidgetItemForm from './../forms/widget_item';

class WidgetItem extends React.Component {
    state = {
        modal: false,
        item_title: {},
    };

    onSubmit = (title, woptions) => {
        this.setState({ modal: false, item_title: ''}, () => {
            let options = this.props.options || {};
            options[`title_${this.props.widgetId}`] = title;
            options = {...options, ...woptions};
            this.props.onUpdateItem(this.props.id, options);
        });
    };


    prepareItemTitle = () => {
        let p = this.props;
        if (p.options && p.options[`title_${this.props.widgetId}`] && p.options[`title_${this.props.widgetId}`] !== p.title) {
            return `${p.options[`title_${this.props.widgetId}`]} (${p.title})`;
        }

        if (p.options && p.options['display_title'] && p.title !== p.options['display_title']) {
            return `${p.options['display_title']} (${p.title})`;
        }

        return p.title;
    };

    prepareItemFormTitle = () => {
        let p = this.props;
        if (p.options && p.options[`title_${this.props.widgetId}`]) {
            return p.options[`title_${this.props.widgetId}`];
        }

        if (p.options && p.options['display_title']) {
            return p.options['display_title'];
        }

        return p.title;
    };

    render() {
        const p = this.props;
        return (
            <div className={`docs--widget_item`}>
                <Modal title={t('EDIT_WIDGET_ITEM')} visible={this.state.modal} onCancel={() => this.setState({modal: false})} destroyOnClose={true} footer={null}>
                    <WidgetItemForm title={this.prepareItemFormTitle()} onSubmit={this.onSubmit} options={this.props.options || {}}/>
                </Modal>
                <div className={`cover`}>
                    <Cover {...p}/>
                </div>
                <div className={`options`}>
                    <div className={`info`}>
                        <div className="title">{this.prepareItemTitle()} ({p.type === 'Banner' ? <Tag color={p.type === 'Banner' ? '#f50' : ''}>{p.type}</Tag> : p.type}) {p.options && p.options.bold_text ? <Tag color="#87d068">{t(`BOLD_TEXT_SELECTED`)}</Tag> : null}</div>
                        <div className={`summary`}>{p.summary && p.summary.length > 70 ? `${p.summary.substr(0, 67)}...` : p.summary}</div>
                        <div className={`published`}>{t('PUBLISHED_AT')}: {Moment(p.publishedAt).format(Config.DATE_FORMAT)}</div>
                        <div className={`preview`}>
                            <Button type={`primary`} size={'small'} icon={'eye'} onClick={() => window.open(`/documents/${p.type.toLowerCase()}/${p.id}`)}>{t('PREVIEW')}</Button>
                        </div>
                    </div>
                    <div className={`actions`}>
                        {p.showAdd && <Button type={`primary`} icon={`plus`} onClick={() => p.onAdd(p)}/>}
                        {!p.showAdd && (
                            <React.Fragment>
                                <Button icon={`up`} disabled={p.index === 0} onClick={() => p.onUpdateSort(p.index, 'up')}/>
                                <Button icon={`down`} disabled={p.index === p.max - 1} onClick={() => p.onUpdateSort(p.index, 'down')} />
                                <Button type={`danger`} icon={`delete`} onClick={() => p.onRemove(p.id)}/>
                                <Tooltip title={t('DISPLAY_TITLE')}>
                                    <Button type={`primary`} icon={`edit`} onClick={() => this.setState({
                                        item_title: p.options && p.options.display_title ? p.options.display_title : "",
                                        modal: true
                                    })}/>
                                </Tooltip>
                            </React.Fragment>
                        )}
                    </div>
                </div>
            </div>
        )
    }
}




export default class Docs_widget extends React.Component {
    static defaultProps = {
        widgetId: '',
        selected: [],
        loadingDocs: false,
        list: [],
        docs_limit: 0,
        docs_total: 0,
        docs_page: 0,
        docs_search: '',
        onPageChange: () => {},
        onAddItem: () => {},
        onSearch: () => {},
        onRemove: () => {},
        onUpdateSort: () => {},
        onUpdateItem: () => {},
    };

    static propTypes = {
        widgetId: PropTypes.string.isRequired,
        loadingDocs: PropTypes.bool.isRequired,
        docs_search: PropTypes.string,
        selected: PropTypes.arrayOf(Object).isRequired,
        list: PropTypes.arrayOf(Object).isRequired,
        docs_limit: PropTypes.number.isRequired,
        docs_total: PropTypes.number.isRequired,
        docs_page: PropTypes.number.isRequired,
        onPageChange: PropTypes.func.isRequired,
        onAddItem: PropTypes.func.isRequired,
        onSearch: PropTypes.func.isRequired,
        onRemove: PropTypes.func.isRequired,
        onUpdateSort: PropTypes.func.isRequired,
    };

    render() {
        return (
            <div className={`docs--widget`}>
                <div className="docs--container docs--selected">
                    <div className="docs--widget__list">
                        {this.props.selected.length === 0 && <Empty description={t('DOCS_NOT_FOUND')}/>}
                        {this.props.selected.length > 0 && this.props.selected.map((i, index) => {
                            return (
                                <WidgetItem
                                    {...i}
                                    index={index}
                                    max={this.props.selected.length}
                                    showAdd={false}
                                    onRemove={this.props.onRemove}
                                    onUpdateSort={this.props.onUpdateSort}
                                    onUpdateItem={this.props.onUpdateItem}
                                    key={`item_${i.id}`}
                                    widgetId={this.props.widgetId}
                                />
                            );
                        })}
                    </div>
                </div>
                <div className="docs--container docs--search">
                    <Spin spinning={this.props.loadingDocs}>
                        <div className="docs--search_filter">
                            <Input.Search
                                placeholder={t(`DOC_SEARCH_PLACEHOLDER`)}
                                enterButton={t('SEARCH')}
                                defaultValue={this.props.docs_search}
                                onSearch={this.props.onSearch}
                            />
                        </div>
                        <div className="docs--widget__list">
                            {this.props.list.length === 0 && <Empty description={t('DOCS_NOT_FOUND')}/>}
                            {this.props.list.length > 0 && this.props.list.map(i => {
                                return <WidgetItem key={`item_${i.id}`} {...i} showAdd={true} onAdd={this.props.onAddItem}/>
                            })}
                        </div>
                        <div className={`pagination`}>
                            <Pagination
                                defaultCurrent={this.props.docs_page}
                                total={this.props.docs_total}
                                current={this.props.docs_page}
                                pageSize={this.props.docs_limit}
                                hideOnSinglePage={true}
                                onChange={this.props.onPageChange}
                            />
                        </div>
                    </Spin>
                </div>
            </div>
        )
    }
}

export {Dashboard as dashboard_index} from './Dashboard';
export {DefaultDocument as default_document} from './Documents/Default';
export {GroupsIndex as groups_index} from './Users/Groups/Index.controller';
export {AddGroup as add_group} from './Users/Groups/Add.controller';
export {UsersIndex as users_index} from './Users/Users/Index.controller';
export {UsersEdit as users_edit} from './Users/Users/Edit.controller';
export {PermissionsIndex as permission_index} from './Users/Permissions/Index.controller';
export {AddDocument as add_documents} from './Documents/Add';
export {PreviewDocument as preview} from './Documents/Preview';
export {MediaIndex as media} from './Media';
export {WidgetIndex as widget} from './Widgets';
export {EditWidget as widget_edit} from './Widgets/edit';
export {ArchiveIndex as archive} from './Archive/index';

/*profiles*/
export {ProfilesController as profile_index} from './Profiles/Profiles.Index';
export {GroupsController as groups_index_profile} from './Profiles/Groups.Index';
export {PermissionsController as permissions_index_profile} from './Profiles/Permissions.Index';
export {ProfilesEdit as profile_edit} from './Profiles/Profile.Edit';

/*reports*/
export {YandexController as yandex_report} from "./Reports/Yandex.Controller";
export {MaterialController as material_report} from "./Reports/Materials.Controller";
export {WidgetReport as widget_report} from "./Reports/Widgets.Controller";
export {VideoController as video_report} from "./Reports/Video.Controller";
export {ViqeoReport as viqeo_report} from "./Reports/Viqeo.Controller";
export {StreamReport as stream_report} from "./Reports/Stream.Controller";
export {MapReport as map_report} from "./Reports/Map.controller";

/*chat*/
export {EpisodeChatController as episode_chat} from './Documents/chat/episode';

/*email*/
export {EmailController as email_controller} from './Reports/Email.controller';

/*budget*/
export {BudgetController as budget} from './Reports/Budget.Controller';
export {ViewBudget as view_budget} from './Reports/ViewBudget.controller';

/*push*/
export {PushController as push} from './PushController'

/*chat controller*/
export { ChatController as chat } from './Chat/Index.controller';

/*mediagallery controller*/
export { MediaIndexController as media_gallery } from './MediaGallery/Index.controller';

/* regions controller */
export { RegionsController as regions_controller } from './Regions/Regions.controller';
export { TTS as tts } from './STT/Index.controller';


/* contacts */
export { ContactsIndex as contacts_index } from './Contacts/Index.controller';

/* rss */
export { RSSIndexController } from './Rss/Index.controller';

export * from './Instruments';

// export { DNDIndex } from './dnd/index.controller';


/* comments */
export { CommentsServiceController, CommentsUsers, StopWordsComments, CommentsController } from './cmoderator/index.js';

/* auth */
export { AuthController } from './Authorization/Index.controller'